

.cursor-pointer {
  cursor: pointer;
  color: darkblue;
}

button.amplify-expander__trigger[data-state="open"]
{
  background-color: lightblue;
}

input.amplify-field-group__control[mylabel="lecture-title"]
{
  /* background-color: inherit; */
}