img.loading {
  width: 2rem;
  height: 2rem;
  background-image: "url('load.gif')";
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  margin-left: 0.5rem;
}

.cls-disabled {
  opacity: 0.30;
}

.cls-textarea {
  border-radius: 10px;
  border: 1px solid black;
  box-shadow:1px 1px 3px 1px var(--amplify-colors-neutral-60);
  padding: 0.4rem;
  width: 100%;
}

.cls-note-textarea {
  background-color: #f5d9bc;
  border-radius: 10px;
  border: 1px solid black;
  box-shadow:1px 1px 3px 1px var(--amplify-colors-neutral-60);
  padding: 0.4rem;
  width: 100%;
}

.cls-notes-common {
  background-color: #f5d9bc;
}

.cls-btn-add-note {
  margin: 0.5rem;
  width: 10rem;
  text-align: center;
  font-size: 1.5rem;
  border-radius: 6px;
  border: 1px solid black;
  padding: 0.5rem;
}

.cls-sentence-textarea {
  background-color: #f5d9bc;
  border-radius: 10px;
  border: 1px solid black;
  box-shadow:1px 1px 3px 1px var(--amplify-colors-neutral-60);
  padding: 0.4rem;
  width: 100%;
}

.cls-transcript-view {
  overflow-y: auto;
  max-height: 500px;
}
.highlight-contains-keyword {
  background-color: orange;
}

.cls-cursor-pointer {
  cursor: pointer;
  color: darkblue;
}

.cls-show-hide-switch {
  border-radius: 6px;
  border: 1px solid black;
  padding: 0.4rem;
  color: black;
  align-self: flex-end;
  margin-bottom: 0;
  font-size: 0.8rem;
  cursor: pointer;
  color: darkblue;
} 

.cls-show-hide-switch.child {
  display: inline-block;
  align-self: flex-end;
}

.cls-text {
  background-color: lightgray;
}

.cls-sentence {
  background-color: lightgray;
}

.cls-announcements {
  background-color: deepskyblue;
}

.cls-entire-summary {
  background-color: greenyellow;
}

.cls-summary {
  background-color: yellowgreen;
}

.cls-notes {
  background-color: #f5d9bc;
}

.cls-capital-letter {
  font-size: 1.5rem;
  font-weight: bold;
}

/* .cls-text-minimized {
  height: 0px;
  overflow: hidden;
  border: 1px solid lightgrey;
} */

.cls-position-relative {
  position: relative;
}

.cls-zindex-10 {
  z-index: 10;
}

.cls-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cls-background-greenyellow {
  background-color: #adff2f;
}

.cls-background-greenyellow-dim {
  background-color: #adff2f80;
}

.cls-background-red-dim {
  background-color: #fc0000ab;
}


.cls-ellipsis-bottom-centered {
  font-size: 3rem;
  padding: 0;
  margin: -2.5rem 46% -1rem;
}

.cls-border-color-black {
  border-color: black;
}

.cls-expandable-class-view {
  overflow-y: auto;
  max-height: 200px;
}

.cls-horizon-chart {
  /* position: fixed;
  top: 10px;
  left: 10px; */
  border: black 1px solid;
  /* width: 80%; */
}

.cls-text-center {
  text-align: center;
}

.cls-vertical-align-center {
  align-self: center;
}

.cls-align-self-start {
  align-self: self-start;
}

.cls-no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.cls-flex-horizontal {
  display: flex;
  flex-direction: row;
  margin: 0px;
}

.cls-flex-horizontal-child {
  flex: 1;
  margin-right: 10px;
}

.cls-width-fill-available
{
  width: -webkit-fill-available;
}

.cls-text-align-right
{
  text-align: -webkit-right;
}

.cls-overflow-Y-scroll
{
  overflow-y: scroll;
}